import React, {Suspense, lazy, useEffect} from 'react';
import Lottie from 'lottie-react';
import ReactGA from 'react-ga';

import { images } from './constants';
import './App.scss';

const Home = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./routes/Home')), 3000);
  });
});  

ReactGA.initialize(process.env.REACT_APP_GOOGLEANALYTICS)

const App = () => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

    return (
    <div className="app">
      <Suspense fallback={<Lottie animationData={images.furniture} autoplay={true} loop={true} className='suspense'/>}>
        <Home />
      </Suspense>
    </div>
  );
}

export default App;