import logo from '../assets/logo.png';
import cabinet from '../assets/cabinetvision.png';
import format from '../assets/format4.png';
import cnc from '../assets/cnc.png';
import tempora from '../assets/tempora.png';
import furniture from '../assets/furniture.json';
import quality from '../assets/quality.json'

const images = {
  logo,
  furniture,
  quality,
  cabinet,
  format,
  cnc,
  tempora,
};

export default images;